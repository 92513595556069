import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import batchService from "services/batchService";

import {
  ActionContent,
  BatchInfoContent,
  BatchInfoSubText,
  BatchInfoText,
  StyledDialog
} from "../../style";
import { Confirm } from "./Confirm";
import { NotReturned } from "./NotReturned";
import { PartialReturn } from "./PartialReturn";
import { ReturnType } from "./ReturnType";

export const FinishPopup = ({
  openFinish,
  handleCloseFinish,
  handleFinish,
  data,
  confirmLoading,
  handleReset,
  handleOpenPopupDeadline,
  refresh
}) => {
  const { addPopup } = usePopup();

  const [page, setPage] = useState(0);
  const [repositionLoading, setRepositionLoading] = useState(false);

  const isReposition = data.stageId === 13;
  const typeOfMeasure = data.quantity?.split(" ")[1];

  const handlePage = page => {
    isReposition ? setPage(page) : handleFinish(3, 11);
  };

  const onClose = () => {
    setPage(0);
    handleCloseFinish();
  };

  const handleConfirmReposition = async values => {
    setRepositionLoading(true);
    try {
      const { id } = data;
      const params = {
        status: 3,
        stage: 11,
        combined_return_qty: values.quantity || values
      };
      await batchService.patchBatchReposition({ id, data: params });
      addPopup({
        type: "success",
        title: "Reposição atualizada com sucesso!"
      });
      onClose();
      refresh();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Houve um erro ao atualizar a reposição",
        description: errorMessage
      });
    } finally {
      setRepositionLoading(false);
    }
  };

  const children = {
    0: (
      <Confirm
        loading={confirmLoading}
        handleCloseFinish={onClose}
        handleOpenPopupDeadline={handleOpenPopupDeadline}
        handleReset={handleReset}
        handlePage={handlePage}
      />
    ),
    1: (
      <ReturnType
        loading={repositionLoading}
        quantity={data?.combinedReturnQty}
        handleCloseFinish={onClose}
        handleOpenPopupDeadline={handleOpenPopupDeadline}
        handleFinish={handleConfirmReposition}
        handlePage={handlePage}
      />
    ),
    2: (
      <PartialReturn
        loading={repositionLoading}
        typeOfMeasure={typeOfMeasure}
        handleFinish={handleConfirmReposition}
        handleCloseFinish={onClose}
      />
    ),
    3: (
      <NotReturned
        typeOfMeasure={typeOfMeasure}
        handleFinish={handleConfirmReposition}
        handleCloseFinish={onClose}
        handleOpenPopupDeadline={handleOpenPopupDeadline}
        handleReset={handleReset}
        loading={repositionLoading}
      />
    )
  }[page];

  const textDescription = {
    0: confirmLoading
      ? "Confirmando, aguarde..."
      : "Deseja confirmar a devolução?",
    1: "Qual a situação do lote?",
    2: "Qual a quantidade devolvida?",
    3: "O que deseja fazer?"
  }[page];

  useEffect(() => {
    setPage(isReposition ? 1 : 0);
  }, [data]);

  return (
    <StyledDialog
      open={openFinish}
      handleClose={onClose}
      title="Finalizar devolução"
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
        <BatchInfoText>
          Instruções: <span>{data?.instructions}</span>
        </BatchInfoText>
      </BatchInfoContent>
      <ActionContent>
        <BatchInfoSubText>{textDescription}</BatchInfoSubText>
        {children}
      </ActionContent>
    </StyledDialog>
  );
};
