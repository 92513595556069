import { Box, RadioGroup } from "@material-ui/core";
import { useState } from "react";
import { ButtonRadio } from "shared";

import { ButtonsContent, StyledButton } from "../../../style";

export const NotReturned = ({
  handleFinish,
  handleCloseFinish,
  handleOpenPopupDeadline,
  handleReset,
  loading
}) => {
  const [option, setOption] = useState(null);

  const handleChangeOption = value => {
    setOption(value);
  };

  const handleClose = () => {
    setOption(null);
    handleCloseFinish();
  };

  const handleConfirm = () => {
    const action =
      {
        prazo: () => {
          handleClose();
          handleOpenPopupDeadline();
        },
        alinhamento: () => {
          handleClose();
          handleReset();
        },
        naoRepos: () => handleFinish(0)
      }[option] ?? (() => {});
    action();
  };

  return (
    <Box display="flex" flexDirection="column" style={{ gap: "2rem" }}>
      <RadioGroup
        value={option}
        onChange={(_, value) => handleChangeOption(value)}
      >
        <Box display="flex" flexDirection="column" style={{ gap: "1rem" }}>
          <ButtonRadio value="prazo" label="Alterar Prazo" />
          <ButtonRadio value="alinhamento" label="Voltar para Alinhamento" />
          <ButtonRadio value="naoRepos" label="Seller não Repôs o Produto" />
        </Box>
      </RadioGroup>
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="updateBtn"
          onClick={handleClose}
          style={{ textTransform: "none" }}
        >
          Cancelar
        </StyledButton>
        <StyledButton
          variant="contained"
          name="confirmButton"
          onClick={handleConfirm}
          style={{ textTransform: "none" }}
          fullWidth
          disabled={!option || loading}
        >
          Avançar
        </StyledButton>
      </ButtonsContent>
    </Box>
  );
};
