import { Box, InputLabel, Paper, Typography, styled } from "@material-ui/core";

export const ConfirmContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  background: "#F5F8F3",
  border: "2px solid #B5C9A8",
  fontSize: "1rem",
  fontWeight: 500,
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "0.5rem",
  gap: "0.5rem"
});

export const LabelConfirmQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#757575"
});

export const LabelConfirmLowQuality = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "0.9rem",
  color: "#D84315"
});

export const LabelSampleQuantity = styled(Typography)({
  fontFamily: "Roboto",
  fontWeight: 600,
  fontSize: "1.1rem"
});

export const ClassificationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
}));

export const ClassificationContent = styled(Paper)(
  ({ theme, classification }) => {
    const topColor = {
      A: "#4EAA68",
      B: "#B4C932",
      C: "#E3DB24"
    }[classification];
    return {
      position: "relative",
      display: "flex",
      flex: 1,
      gap: "1rem",
      padding: "2rem 1rem 1rem 1rem",
      "&.MuiPaper-root": {
        borderRadius: "1rem 1rem 0.5rem 0.5rem"
      },
      "&::before": {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        borderTop: `0.7rem solid ${topColor}`,
        borderRadius: "0.5rem 0.5rem 0 0",
        pointerEvents: "none",
        zIndex: 1
      }
    };
  }
);

export const TitleUnclassified = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "0.875rem",
  color: "#EB001B",
  lineHeight: "normal"
});

export const AttachmentContent = styled(Box)({
  display: "flex"
});
