import {
  Box,
  Paper,
  TableBody,
  Tooltip,
  Typography,
  Zoom
} from "@material-ui/core";
import { CustomLineProgress } from "components/LineProgress";
import { RiErrorWarningLine } from "react-icons/ri";

import {
  BodyCell,
  BodyRow,
  ConfirmButton,
  HeadCell,
  StyledTable,
  StyledTableHead,
  TableContent
} from "../../style";

export const BatchTable = ({
  batchData,
  loading,
  handleOpenFinish,
  status,
  stage
}) => {
  const isUpdated = batch => {
    return (
      batch.createdDate !== batch.updatedDate ||
      batch.createdTime !== batch.updatedTime
    );
  };

  const isAlignedDateVisible = () => {
    return !status || (status === 3 && [12, 13].includes(stage));
  };

  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <HeadCell width="1%">Lote</HeadCell>
            <HeadCell width="11%">Recebido em</HeadCell>
            <HeadCell width="11%">Selecionado em</HeadCell>
            {isAlignedDateVisible() && (
              <HeadCell width="11%">Alinhado em</HeadCell>
            )}
            <HeadCell width="8%">Produto</HeadCell>
            <HeadCell width="12%">Seller</HeadCell>
            <HeadCell>Qtd</HeadCell>
            <HeadCell width="9%">Motivo</HeadCell>
            <HeadCell>Status</HeadCell>
            <HeadCell>Estágio</HeadCell>
            <HeadCell width="10%">Devolução combinada</HeadCell>
            {status === 3 && [12, 13].includes(stage) && (
              <>
                <HeadCell width="11%">Qtd combinada</HeadCell>
                <HeadCell width="11%">Seller vai retirar</HeadCell>
              </>
            )}
            {status === 3 && [12].includes(stage) && (
              <HeadCell width="11%">Prazo devolução</HeadCell>
            )}
            {status === 3 && [13].includes(stage) && (
              <HeadCell width="11%">Prazo de reposição</HeadCell>
            )}
            <HeadCell></HeadCell>
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {batchData?.map(batch => (
              <BodyRow key={batch.id}>
                <BodyCell>{batch.batchId}</BodyCell>
                <BodyCell>
                  {batch.receiptDate} {batch.receiptTime}
                </BodyCell>
                <BodyCell>
                  {batch.createdDate} {batch.createdTime}
                </BodyCell>
                {isAlignedDateVisible() && (
                  <BodyCell>
                    {[12, 13].includes(stage) && isUpdated(batch) && (
                      <>
                        {batch.updatedDate} {batch.updatedTime}
                      </>
                    )}
                  </BodyCell>
                )}
                <BodyCell>{batch.productName}</BodyCell>
                <BodyCell>{batch.seller}</BodyCell>
                <BodyCell>{batch.quantity}</BodyCell>
                <BodyCell>{batch.reason}</BodyCell>
                <BodyCell>{batch.status}</BodyCell>
                <BodyCell>{batch.stage}</BodyCell>
                <BodyCell>{batch.instructions}</BodyCell>
                {status === 3 && [12, 13].includes(stage) && (
                  <>
                    <BodyCell width="11%">
                      {batch.formattedCombinedReturnQty}
                    </BodyCell>
                    <BodyCell width="11%">{batch.sellerWillPickup}</BodyCell>
                  </>
                )}
                {status === 3 && [12].includes(stage) && (
                  <BodyCell>{batch.deadlineForAnalysis}</BodyCell>
                )}
                {status === 3 && [13].includes(stage) && (
                  <BodyCell>{batch.combinedReturnDeadline}</BodyCell>
                )}
                <BodyCell>
                  {!!batch?.instructionsEditedBy?.username && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        <>
                          <Typography>
                            {`Devolução combinada editada por: ${batch?.instructionsEditedBy?.username}`}
                          </Typography>
                          <Typography>
                            De:{" "}
                            <strong>
                              {
                                batch?.purchaseItem?.purchase
                                  ?.refusal_instructions
                              }
                            </strong>
                          </Typography>
                          <Typography>
                            Para: <strong>{batch?.instructions}</strong>
                          </Typography>
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <RiErrorWarningLine color="red" size={20} />
                      </Box>
                    </Tooltip>
                  )}
                </BodyCell>
                <BodyCell>
                  {status === 3 && [12, 13].includes(stage) && (
                    <ConfirmButton onClick={() => handleOpenFinish(batch.id)}>
                      Finalizar
                    </ConfirmButton>
                  )}
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContent>
    </Box>
  );
};
