import { Box, Typography } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { MainContent } from "components/Contents";
import { SearchInput } from "components/Inputs";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import { BsKanban } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import batchService from "services/batchService";
import { useDebaunce } from "utils";
import { unauthorizedUsers } from "utils/checkUnauthorizedUsers";

import { Paginator } from "./components/Paginator";
import { ReasonFilter } from "./components/ReasonFIlter";
import { ReceiptDateFilter } from "./components/ReceiptDateFilter";
import { SelectionAccordion } from "./components/SelectionAccordion";
import { SelectionPopup } from "./components/SelectionPopup";
import { StyledSearchContent, ButtonLink } from "./style";
import { formatterData } from "./utils/formatterData";

export const BatchSelectionPage = () => {
  const history = useHistory();
  const location = useLocation();
  const batchId = new URLSearchParams(location.search).get("batchId");
  const fromKanban = !!new URLSearchParams(location.search).get("fromKanban");
  const selectionGroupBatchId = new URLSearchParams(location.search).get(
    "selectionGroupBatchId"
  );
  const { user } = useAuth();
  const { addPopup } = usePopup();
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openSelectionPopup, setOpenSelectionPopup] = useState(false);
  const [detailBatchData, setDetailBatchData] = useState({});
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [selectedFilterReason, setSelectedFilterReason] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const isUnauthorizedUser = unauthorizedUsers.batch(user);

  const debaunceSearch = useDebaunce({ fn: handleSearch, delay: 800 });
  const debaunceFilterDate = useDebaunce({ fn: handleFilterDate, delay: 800 });
  const debaunceFilterReason = useDebaunce({
    fn: handleFilterReason,
    delay: 800
  });

  const getInitData = async () => {
    try {
      setLoading(true);
      const { data } = await batchService.getBatchesV2({
        page,
        search: search?.trim(),
        receiptDate: selectedFilterDate,
        refusalInstructionsId:
          selectedFilterReason && String(selectedFilterReason),
        pageSize: 10,
        isSelection: true,
        hasAvailableQuantity: true
      });
      const formatData = formatterData(data.results);
      setBatchData(formatData);
      setTotalPages(data.pageSize);
      setTotalCount(data.totalCount);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const { data } = await batchService.getBatchesV2({
        id: batchId || selectionGroupBatchId
      });
      const detailBatch = formatterData(data.results).find(
        ({ id }) => id === (Number(batchId) || Number(selectionGroupBatchId))
      );
      setDetailBatchData(detailBatch);
      setOpenSelectionPopup(true);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao carregar lotes",
        description: errorMessage
      });
    }
  };

  function handleSearch(e) {
    setPage(1);
    const { value } = e.target;
    setSearch(value);
  }

  const handleChangePage = e => {
    const anchor = document.querySelector("#back-to-top-anchor");
    !!anchor && anchor.scrollIntoView({ behavior: "smooth" });

    const selectedPage = e.selected + 1;
    setPage(selectedPage);
  };

  const handleOpenSelectionPopup = batchId => {
    const detailBatch = batchData.find(({ id }) => id === batchId);
    setDetailBatchData(detailBatch);
    setOpenSelectionPopup(true);
  };

  const handleCloseSelectionPopup = () => {
    if (batchId || selectionGroupBatchId) {
      history.goBack();
    }
    setOpenSelectionPopup(false);
  };

  const reloadData = () => {
    page === 1 ? getInitData() : setPage(1);
  };

  function resetPage() {
    setPage(1);
  }

  function handleFilterDate(e) {
    const { value } = e.target;
    const formattedValue = value && new Date(value).toISOString();
    resetPage();
    setSelectedFilterDate(formattedValue);
  }

  function handleFilterReason(e) {
    const { value } = e.target;
    resetPage();
    setSelectedFilterReason(value);
  }

  useEffect(() => {
    getInitData();
    if (batchId || selectionGroupBatchId) {
      getData();
    }
  }, [page, search, selectedFilterDate, selectedFilterReason]);

  if (isUnauthorizedUser) {
    return <Redirect to="/" />;
  }

  return (
    <ContainerPage>
      <Box
        id="back-to-top-anchor"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        mb="0.5rem"
        style={{ gap: "1rem" }}
      >
        <TitlePage>Seleção de lote (Tela do Breno)</TitlePage>

        <ButtonLink to="/app/dashboard/kanban-grupo-de-selecao">
          <BsKanban size={24} />
          Quadro de seleção
        </ButtonLink>
      </Box>
      <MainContent>
        <StyledSearchContent>
          <SearchInput
            placeholder="Seller, produto, código.."
            onChange={debaunceSearch}
          />
          <ReasonFilter
            selected={selectedFilterReason}
            handleFilter={debaunceFilterReason}
          />
          <ReceiptDateFilter handleFilterDate={debaunceFilterDate} />
          <Typography style={{ marginLeft: "auto", color: "#353535" }}>
            Total de lotes: {totalCount}
          </Typography>
        </StyledSearchContent>
        <SelectionAccordion
          batchData={batchData}
          loading={loading}
          handleOpenSelectionPopup={handleOpenSelectionPopup}
        />
        <SelectionPopup
          handleCloseSelectionPopup={handleCloseSelectionPopup}
          openSelectionPopup={openSelectionPopup}
          fromKanban={fromKanban}
          detailBatchData={detailBatchData}
          reloadData={reloadData}
        />
        <Paginator
          handleChangePage={handleChangePage}
          loading={loading}
          page={page}
          totalPages={totalPages}
        />
      </MainContent>
    </ContainerPage>
  );
};
