import { Box, RadioGroup } from "@material-ui/core";
import { useState } from "react";
import { ButtonRadio } from "shared";

import { ButtonsContent, StyledButton } from "../../../style";

export const ReturnType = ({
  handleFinish,
  handleCloseFinish,
  handlePage,
  quantity,
  loading
}) => {
  const [type, setType] = useState("full");

  const handleChangeType = value => {
    setType(value);
  };

  const handleClose = () => {
    setType("full");
    handleCloseFinish();
  };

  const handleConfirm = () => {
    const action =
      {
        full: () => handleFinish(quantity),
        partial: () => handlePage(2),
        none: () => handlePage(3)
      }[type] ?? (() => {});
    action();
  };

  return (
    <Box display="flex" flexDirection="column" style={{ gap: "2rem" }}>
      <RadioGroup value={type} onChange={(_, value) => handleChangeType(value)}>
        <Box display="flex" flexDirection="column" style={{ gap: "1rem" }}>
          <ButtonRadio value="full" label="Devolvido" />
          <ButtonRadio value="partial" label="Devolvido Parcialmente" />
          <ButtonRadio value="none" label="Não Reposto" />
        </Box>
      </RadioGroup>
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="updateBtn"
          onClick={handleClose}
          style={{ textTransform: "none" }}
        >
          Cancelar
        </StyledButton>
        <StyledButton
          variant="contained"
          name="confirmButton"
          onClick={handleConfirm}
          style={{ textTransform: "none" }}
          fullWidth
          disabled={loading}
        >
          Confirmar
        </StyledButton>
      </ButtonsContent>
    </Box>
  );
};
