import { CircularProgress } from "@material-ui/core";

import { ButtonsContent, LoadingContent, StyledButton } from "../../../style";

export const Confirm = ({
  handleCloseFinish,
  handlePage,
  handleReset,
  loading
}) => {
  const actionContent = {
    true: (
      <LoadingContent>
        <CircularProgress color="primary" />
      </LoadingContent>
    ),
    false: (
      <ButtonsContent>
        <StyledButton
          variant="contained"
          fullWidth
          name="updateBtn"
          onClick={handleCloseFinish}
          style={{ textTransform: "none" }}
        >
          Cancelar
        </StyledButton>

        <StyledButton
          variant="contained"
          fullWidth
          name="updateBtn"
          onClick={handleReset}
          style={{ textTransform: "none" }}
        >
          Voltar p/ alinhamento
        </StyledButton>

        <StyledButton
          variant="contained"
          fullWidth
          name="confirmButton"
          onClick={() => handlePage(1)}
          style={{ textTransform: "none" }}
        >
          Confirmar
        </StyledButton>
      </ButtonsContent>
    )
  }[loading];
  return actionContent;
};
