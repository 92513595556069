import { Box, TextField } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ButtonsContent, StyledButton } from "pages/private/BatchReturn/style";
import { useState } from "react";
import * as Yup from "yup";

const validationSchema = typeOfMeasure =>
  Yup.object().shape({
    quantity: Yup.number()
      .typeError("Deve ser um número")
      .positive("Deve ser um número positivo")
      .required("Valor obrigatório")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(typeOfMeasure === "Un" && !Number.isInteger(value));
        }
      )
  });

export const PartialReturn = ({
  handleFinish,
  handleCloseFinish,
  typeOfMeasure,
  loading
}) => {
  const initialValues = {
    quantity: ""
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(typeOfMeasure)}
      validateOnChange
      onSubmit={handleFinish}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem"
        }}
      >
        <Box>
          <Field
            as={TextField}
            name="quantity"
            type="number"
            variant="outlined"
            fullWidth
            autoFocus
            placeholder={typeOfMeasure}
            inputProps={{
              type: "number",
              min: 0,
              step: "any"
            }}
          />
          <ErrorMessage
            name="quantity"
            component="div"
            style={{ color: "red" }}
          />
        </Box>
        <ButtonsContent>
          <StyledButton
            variant="contained"
            name="updateBtn"
            onClick={handleCloseFinish}
            fullWidth
          >
            Cancelar
          </StyledButton>
          <StyledButton
            type="submit"
            variant="contained"
            name="confirmButton"
            disabled={loading}
            fullWidth
          >
            Confirmar
          </StyledButton>
        </ButtonsContent>
      </Form>
    </Formik>
  );
};
