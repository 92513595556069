import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { usePopup } from "../../hooks/usePopup";
import sellerService from "../../services/sellerService";
import { useDebaunce } from "../../utils/debaunce";

const AutoCompleteSeller = ({
  errors,
  name,
  control,
  onChange,
  disabled,
  ...rest
}) => {
  const { addPopup } = usePopup();
  const isDisabled = !!disabled;
  const [loadingSearchSeller, setLoadingSearchSeller] = useState(false);
  const [sellerProps, setSellerProps] = useState({
    options: [],
    getOptionLabel: option => {
      const code = option?.code?.toString().replace(/[^0-9]/g, "") || "";
      return `${code ? `${code} - ` : ""}${option.name || ""}`;
    }
  });

  const handleSellerData = async (search = undefined) => {
    try {
      setLoadingSearchSeller(true);
      const { data } = await sellerService.getSellers({
        page: 1,
        pageSize: 15,
        search
      });

      setSellerProps({ ...sellerProps, options: data.results });
    } catch (err) {
      if (err?.response?.status) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao pesquisar seller."
        });
      }
    } finally {
      setLoadingSearchSeller(false);
    }
  };

  const debaunce = useDebaunce({ fn: handleSellerData, delay: 500 });

  const handleSearchSeller = e => {
    debaunce(e.target.value);
  };

  useEffect(() => {
    handleSellerData();
  }, []);

  const filterOptions = (options, state) => {
    return options.filter(option => {
      const label = sellerProps.getOptionLabel(option);
      return label.trim() !== "";
    });
  };

  if (control) {
    return (
      <Controller
        render={props => (
          <Autocomplete
            loading={loadingSearchSeller}
            {...props}
            {...sellerProps}
            disabled={isDisabled}
            onChange={(_, data) => props.field.onChange(data)}
            value={props.field.value}
            noOptionsText="Fornecedor não encontrado"
            loadingText="Procurando fornecedor..."
            filterOptions={filterOptions}
            renderInput={params => (
              <TextField
                {...params}
                onChange={handleSearchSeller}
                variant="outlined"
                label="Fornecedores"
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingSearchSeller && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        name={name}
        control={control}
      />
    );
  }

  return (
    <Autocomplete
      loading={loadingSearchSeller}
      {...sellerProps}
      onChange={onChange}
      disabled={isDisabled}
      noOptionsText="Fornecedor não encontrado"
      loadingText="Procurando fornecedor..."
      filterOptions={filterOptions}
      {...rest}
      renderInput={params => (
        <TextField
          {...params}
          onChange={handleSearchSeller}
          variant="outlined"
          label="Fornecedores"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSearchSeller && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default AutoCompleteSeller;
